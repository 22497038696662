import React, {useState} from "react";

import {
    Button, Checkbox, Collapse,
    Container,
    FormControlLabel, FormGroup,
    InputAdornment, Link,
    makeStyles, Switch,
    TextField,
    Typography
} from "@material-ui/core";

import ConfirmModal from "../../Modal/ConfirmModal";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {config} from "../../../util/constans";

import TermsOfUse from "../../../docs/Terms_of_Use_revised.pdf"
import AMLPolicy from "../../../docs/AML_policy.pdf"
import CookiePolicy from "../../../docs/COOKIE_POLICY.pdf"
import PersonalDataStoragePolicy from "../../../docs/Personal_data_storage_policy.pdf"

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

}));

export default function Furtune({session, setSession, setStatusSession, partner, id}) {

    const classes = useStyles();

    const history = useHistory();

    const [dirty, setDirty] = useState({
        email: false,
        emailConfirmCode: false,
        phone: false,
        phoneConfirmCode: false,
        lastName: false,
        firstName: false,
        companyName: false
    });
    const [company, setCompany] = useState(false);
    const [unique, setUnique] = useState({
        email: '',
        phone: '',
        lastName: '',
        firstName: '',
        isCompany: false,
        companyName: ""
    });
    const [params, setParams] = useState({
        email: '',
        phone: '',
        lastName: '',
        firstName: '',
        isCompany: false,
        companyName: ""
    });
    const [paramsConfirm, setParamsConfirm] = useState({
        emailConfirmCode: '',
        phoneConfirmCode: '',
    });
    const [policy, setPolicy] = useState({
        AMLPoliceFRTNTrade: false,
        PersonalDataCookies: false,
        ConfirmInfo: false
    });
    const [openModal, setOpenModal] = useState(false);
    const [messageConfirm, setMessageConfirm] = useState('')
    const [errorConfirm, setErrorConfirm] = useState(false)
    const [disabledResendButton, setDisabledResendButton] = useState(true)
    const [seconds, setSeconds] = useState(120);
    const [euCountry, setEuCountry] = useState(false);
    const [trySubmit, setTrySubmit] = useState(4);


    const handleChange = (event) => {
        setParams({...params, [event.target.name]: event.target.value});
    };

    const handleChecked = (event) => {
        setEuCountry(event.target.checked);
    };

    const handleChangeConfirm = (event) => {
        setParamsConfirm({...paramsConfirm, [event.target.name]: event.target.value.toUpperCase()});
    };

    const handleChangePolicy = (event) => {
        setPolicy({...policy, [event.target.name]: event.target.checked});
    };

    const handleChangeIsCompany = (event) => {
        setParams({...params, isCompany: event.target.checked, companyName: ""});
        setDirty({...dirty, companyName: false})
    };


    const blurHandler = (e) => {
        if (e.target.name === "email") {
            setDirty({...dirty, email: true})
        } else if (e.target.name === "phone") {
            setDirty({...dirty, phone: true})
        } else if (e.target.name === "lastName") {
            setDirty({...dirty, lastName: true})
        } else if (e.target.name === "firstName") {
            setDirty({...dirty, firstName: true})
        }
        if (e.target.name === "companyName") {
            setDirty({...dirty, companyName: true})
        }
    };

    const paramsValid = (data) => {
        let flag = false
        for (let key in data) {
            if (validate(key, data[key])) {
                flag = true
            } else {
                flag = false
                break
            }
        }
        return flag;
    };

    const validate = (name, value) => {
        if (params.isCompany) {
            if (name === "email") {
                const emailRe = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                return emailRe.test(String(value).toLowerCase());
            } else if (name === "phone") {
                const phoneRe = /[0-9]/;
                return value.length > 7 && phoneRe.test(String(value).toLowerCase());
            } else if (name === "companyName") {
                return value.length > 0
            } else if (name === "isCompany") {
                return true
            } else if (name === "lastName") {
                return true
            } else if (name === "firstName") {
                return true
            }
        } else {
            if (name === "email") {
                const emailRe = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                return emailRe.test(String(value).toLowerCase());
            } else if (name === "phone") {
                const phoneRe = /[0-9]/;
                return value.length > 7 && phoneRe.test(String(value).toLowerCase());
            } else if (name === "lastName") {
                return value.length > 0
            } else if (name === "firstName") {
                return value.length > 0
            } else if (name === "companyName") {
                return true
            } else if (name === "isCompany") {
                return true
            }
        }
    };

    const submit = () => {
        setUnique({...unique, email: '', phone: ''})
        const data = {...params, euPerson: euCountry, partner: partner}
        if (!trySubmit <= 0) {
            axios.post('registration/createRegistration', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
                baseURL: config.url.API_URl,
            })
                .then(function (response) {
                    if (response.data.status) {
                        setUnique({...unique, email: response.data.error.email, phone: response.data.error.phone})
                    } else {
                        setCompany(response.data.isCompany)
                        setSession(response.data.session)
                        setStatusSession(response.data.status)
                        setTrySubmit(trySubmit - 1)
                        setOpenModal(true)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
    }
    const confirm = () => {
        axios.post('registration/confirmRegistration', {
            session: session,
            phoneConfirmCode: paramsConfirm.phoneConfirmCode,
            emailConfirmCode: paramsConfirm.emailConfirmCode,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
            baseURL: config.url.API_URl,
        })
            .then(function (response) {
                if (response.data.status === 1) {
                    setMessageConfirm(response.data.message)
                    setErrorConfirm(true)
                } else {
                    if(company){
                        history.push('/submission-accepted')
                    } else {
                        localStorage.setItem("sumSubToken", response.data.sumSubToken)
                        setOpenModal(false)
                        setErrorConfirm(false)
                        history.push(`/s/${session}`)
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    const resendConfirm = () => {
        axios.post('registration/resendConfirmCodes', {session: session, invoiceId: id}, {
            headers: {
                'Content-Type': 'application/json',
            },
            baseURL: config.url.API_URl,
        })
            .then(function (response) {
                setSeconds(120)
                setDisabledResendButton(true)
                setTrySubmit(trySubmit - 1)
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }
    const handleClose = () => {
        setOpenModal(false);
        setDisabledResendButton(true)
        setSeconds(120)
    };
    return (
        <Container
            component="main"
            maxWidth="xs"
            className={classes.main}
        >
            <Typography
                component="h1"
                variant="h5"
            >
                Contact information
            </Typography>
            <form
                className={classes.form}
                noValidate
            >
                <TextField
                    error={(dirty.email && !validate("email", params.email)) || unique.email}
                    onBlur={blurHandler}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    helperText={unique.email}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleChange}
                />
                <TextField
                    error={(dirty.phone && !validate('phone', params.phone)) || (unique.phone)}
                    onBlur={blurHandler}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                +
                            </InputAdornment>
                        ),
                    }}
                    helperText={unique.phone}
                    name="phone"
                    label="Phone"
                    type="phone"
                    id="phone"
                    onChange={handleChange}
                />
                <Collapse in={!params.isCompany}>
                    <TextField
                        onBlur={blurHandler}
                        error={dirty.firstName && !validate("firstName", params.firstName)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="firstName"
                        label="First name"
                        type="text"
                        id="firstName"
                        onChange={handleChange}
                    />
                    <TextField
                        onBlur={blurHandler}
                        error={dirty.lastName && !validate("lastName", params.lastName)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        style={{marginBottom: "10px"}}
                        name="lastName"
                        label="Last name"
                        type="text"
                        id="lastName"
                        onChange={handleChange}
                    />
                </Collapse>

                <FormControlLabel
                    control={
                        <Switch color="primary" checked={params.isCompany} onChange={handleChangeIsCompany} name="isCompany"/>
                    }
                    label={`Legal entity`}
                />
                <Collapse in={params.isCompany}>
                    <TextField
                        onBlur={blurHandler}
                        error={dirty.companyName && !validate("companyName", params.companyName)}
                        variant="outlined"
                        margin="normal"
                        required
                        value={params.companyName}
                        fullWidth
                        style={{marginBottom: "10px"}}
                        name="companyName"
                        label="Company name"
                        type="text"
                        id="companyName"
                        onChange={handleChange}
                    />
                </Collapse>
                <Collapse in={!params.isCompany}>
                    <FormControlLabel
                        style={{marginBottom: "10px"}}

                        control={
                            <Switch color="primary" checked={euCountry} onChange={handleChecked} name="euPerson"/>
                        }
                        label="Are you EU resident?"
                    />
                </Collapse>

                <FormGroup>
                    <div style={{display: "flex", alignItems: "flex-start", margin: "0 0 10px -12px"}}>
                        <Checkbox style={{paddingTop: "0"}} color={'primary'} name="AMLPoliceFRTNTrade"
                                  onChange={handleChangePolicy}/>
                        <Typography>
                            I&nbsp;have read and agree to&nbsp; <Link
                            target="_blank"
                            href={TermsOfUse}
                        >
                            Frtn.trade`s Terms of Use
                        </Link>, <Link
                            target="_blank"
                            href={AMLPolicy}
                        >
                            AML and KYC policy
                        </Link>
                        </Typography>
                    </div>
                    <div style={{display: "flex", alignItems: "flex-start", margin: "0 0 10px -12px"}}>
                        <Checkbox style={{paddingTop: "0"}} name="PersonalDataCookies" color={'primary'}
                                  onChange={handleChangePolicy}/>
                        <Typography>
                            I&nbsp;have read and agree to&nbsp;<Link
                            target="_blank"
                            href={PersonalDataStoragePolicy
                            }
                        >
                            Frtn.trade`s Personal Data storage policy
                        </Link> and <Link
                            target="_blank"
                            href={CookiePolicy}
                        >
                            Cookies policy
                        </Link>
                        </Typography>
                    </div>
                    <div style={{display: "flex", alignItems: "flex-start", margin: "0 0 20px -12px"}}>
                        <Checkbox style={{paddingTop: "0"}} color={'primary'} name="ConfirmInfo"
                                  onChange={handleChangePolicy}/>
                        <Typography>
                            I&nbsp;confirm that the information&nbsp;I have provided is&nbsp;correct, that the documents
                            provided
                            correspond to&nbsp;the original documents and the information provided corresponds
                            to&nbsp;actual
                            conditions. I&nbsp;agree to&nbsp;notify Frtn.trade of&nbsp;any changes to&nbsp;this
                            questionnaire and to&nbsp;provide
                            updated information in&nbsp;writing. I&nbsp;agree that the company has the right
                            to&nbsp;verify the
                            accuracy of&nbsp;the information provided. In&nbsp;the event that the information turns out
                            to&nbsp;be
                            inaccurate, Frtn.trade has the right to&nbsp;refuse to&nbsp;open an&nbsp;account.
                            I&nbsp;undertake to&nbsp;provide
                            the information and&nbsp;/ or&nbsp;documents requested by&nbsp;the company within the
                            timeframe se&nbsp;t&nbsp;by&nbsp;the
                            company
                        </Typography>
                    </div>
                </FormGroup>

                <Button
                    fullWidth
                    disabled={!(paramsValid(params) && policy.AMLPoliceFRTNTrade && policy.PersonalDataCookies && policy.ConfirmInfo) || trySubmit <= 0}
                    variant="contained"
                    color="primary"
                    onClick={submit}
                >
                    Next step
                </Button>
            </form>
            {trySubmit <= 0 ? <Typography
                color="error"
                variant="h7"
            >Sorry, but you have exceeded the allowed number of&nbsp;email and phone confirmation
                code requests. Please try to&nbsp;register later.</Typography> : null}
            <ConfirmModal
                hideBackdrop={true}
                trySubmit={trySubmit}
                disabledResendButton={disabledResendButton}
                setDisabledResendButton={setDisabledResendButton}
                seconds={seconds}
                setSeconds={setSeconds}
                emailConfirmCode={paramsConfirm.emailConfirmCode}
                phoneConfirmCode={paramsConfirm.phoneConfirmCode}
                message={messageConfirm}
                error={errorConfirm}
                open={openModal}
                setOpen={setOpenModal}
                params={paramsConfirm}
                setParams={setParamsConfirm}
                handleChange={handleChangeConfirm}
                resendConfirm={resendConfirm}
                confirm={confirm}
                handleClose={handleClose}
            />
        </Container>
    );
}
;
