import React from 'react';
import styles from './primaryParagraph.module.css';

export function PrimaryParagraph({children, ...props}) {
    return (
        <p
            className={styles.css}
            {...props}
        >
            {children}
        </p>
    );
}