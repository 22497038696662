import React from 'react'

import { Container, makeStyles } from '@material-ui/core'

import JackPayments from './JackPayments'
import Fortune from './Fortune/Fortune'
import Default from './Default/Default'
import { Route } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}))
// TODO Перенести поля на форму контакта
export default function ContactForm ({
  session,
  setSession,
  setStatusSession,
  partner,
  id,
}) {
  const classes = useStyles()
  return (
    partner === 'jp' ?
      <Container
        component="main"
        maxWidth="xs"
        className={classes.main}
      >
        <JackPayments
          partner={partner}
          id={id}
          session={session}
          setSession={setSession}
          setStatusSession={setStatusSession}
        />
      </Container>
      : partner === 'frtn' ?
        <Container
          component="main"
          maxWidth="xs"
          className={classes.main}
        >
          <Fortune
            partner={partner}
            id={id}
            session={session}
            setSession={setSession}
            setStatusSession={setStatusSession}
          />
        </Container>
          : partner === 'cryptostan' ?
            <Default
              partner={partner}
              id={id}
              session={session}
              setSession={setSession}
              setStatusSession={setStatusSession}
            /> :
              <Route
                path={"/"}
                exact={true}
              >
                <Default
                  partner={partner}
                  id={id}
                  session={session}
                  setSession={setSession}
                  setStatusSession={setStatusSession}
                />
              </Route>
  )
}
