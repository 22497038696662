import React, {useEffect} from "react";

import {Button, Grid, makeStyles, Modal, TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(8),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function ConfirmModal({
                                         open,
                                         setOpen,
                                         handleChange,
                                         confirm,
                                         message,
                                         error,
                                         phoneConfirmCode,
                                         emailConfirmCode,
                                         resendConfirm,
                                         seconds,
                                         setSeconds,
                                         disabledResendButton,
                                         setDisabledResendButton,
                                         trySubmit
                                     }) {
    const classes = useStyles();
    const handleClose = () => {
        setOpen(false);
        setDisabledResendButton(true)
        setSeconds(120)
    };

    useEffect(() => {
        if (open && seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } if (open && seconds <= 0 && trySubmit > 0){
            setDisabledResendButton(false)
        }

    }, [seconds, open, setSeconds, disabledResendButton, setDisabledResendButton, trySubmit, setOpen]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div className={classes.paper}>
                <h2 id="simple-modal-title">Please enter confirmation codes</h2>
                <form
                    id="simple-modal-description"
                    className={classes.form}
                    noValidate
                >
                    <TextField
                        value={emailConfirmCode}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="emailConfirmCode"
                        label="Email confirm"
                        name="emailConfirmCode"
                        type="text"
                        autoFocus
                        error={error}
                        helperText={message}
                        onChange={handleChange}
                    />
                    <TextField
                        value={phoneConfirmCode}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="phoneConfirmCode"
                        label="Phone-confirm"
                        type="text"
                        id="phoneConfirmCode"
                        error={error}
                        helperText={message}
                        onChange={handleChange}
                    />

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid itemType>
                            <Button
                                disabled={disabledResendButton || trySubmit <= 0}
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={resendConfirm}
                            >
                                Resend code {disabledResendButton && trySubmit > 0 ? seconds : null}
                            </Button>
                        </Grid>
                        <Grid itemType>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={confirm}
                            >
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
};
