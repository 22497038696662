import React from 'react';
import styles from './PrimaryLink.module.css';


export function PrimaryLink({children, ...props}) {
    return (
        <a
            className={styles.css}
            {...props}
        >
            {children}
        </a>
    );
}
