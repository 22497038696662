import React, {useState} from "react";

import {
    Button,
    Container,
    FormControlLabel,
    InputAdornment,
    makeStyles, Switch,
    TextField,
    Typography
} from "@material-ui/core";

import ConfirmModal from "../Modal/ConfirmModal";
import axios from "axios";
import {config} from "../../util/constans";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

}));

export default function JackPayments({session, setSession, setStatusSession, partner, id}) {

    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);
    const [messageConfirm, setMessageConfirm] = useState('')
    const [errorConfirm, setErrorConfirm] = useState(false)
    const [disabledResendButton, setDisabledResendButton] = useState(true)
    const [seconds, setSeconds] = useState(120);
    const [trySubmit, setTrySubmit] = useState(4);

    const [euCountry, setEuCountry] = useState(false);
    const [dirty, setDirty] = useState({
        email: false,
        emailConfirmCode: false,
        phone: false,
        phoneConfirmCode: false,
        lastName: false,
        firstName: false
    });
    const [unique, setUnique] = useState({
        email: '',
        phone: '',
        lastName: '',
        firstName: ''
    });
    const [params, setParams] = useState({
        email: '',
        phone: '',
        lastName: '',
        firstName: '',
    });
    const [paramsConfirm, setParamsConfirm] = useState({
        emailConfirmCode: '',
        phoneConfirmCode: '',
    });

    const handleChecked = (event) => {
        setEuCountry(event.target.checked)
    }
    const handleChangeConfirm = (event) => {
        setParamsConfirm({...paramsConfirm, [event.target.name]: event.target.value.toUpperCase()})
    }
    const handleChange = (event) => {
        setParams({...params, [event.target.name]: event.target.value})
    }

    const handleClose = () => {
        setOpenModal(false);
        setDisabledResendButton(true)
        setSeconds(120)
    };
    const blurHandler = (e) => {
        if (e.target.name === "email") {
            setDirty({...dirty, email: true})
        } else if (e.target.name === "phone") {
            setDirty({...dirty, phone: true})
        } else if (e.target.name === "lastName") {
            setDirty({...dirty, lastName: true})
        } else if (e.target.name === "firstName") {
            setDirty({...dirty, firstName: true})
        }
    }
    const paramsValid = (data) => {
        let flag = false
        for (let key in data) {
            if (validate(key, data[key])) {
                flag = true
            } else {
                flag = false
                break
            }
        }
        return flag
    }
    
    const validate = (name, value) => {
        if (name === "email") {
            const emailRe = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return emailRe.test(String(value).toLowerCase());
        } else if (name === "phone") {
            const phoneRe = /[0-9]/;
            return value.length > 7 && phoneRe.test(String(value).toLowerCase());
        } else if (name === "lastName") {
            return value.length > 0
        } else if (name === "firstName") {
            return value.length > 0
        }
    }

    const submit = () => {
        setUnique({...unique, email: '', phone: ''})
        const data = {...params, euPerson: euCountry, partner: partner, invoice: id}
        if (!trySubmit <= 0) {
            axios.post('registration/createRegistration', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
                baseURL: config.url.API_URl,
            })
                .then(function (response) {
                    if(response.data.status){
                        setUnique({...unique, email: response.data.error.email, phone: response.data.error.phone})
                    } else{
                        setSession(response.data.session)
                        setStatusSession(response.data.status)
                        setTrySubmit(trySubmit - 1)
                        setOpenModal(true)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
    }
    const confirm = () => {
        axios.post('registration/confirmRegistration', {
            session: session,
            phoneConfirmCode: paramsConfirm.phoneConfirmCode,
            emailConfirmCode: paramsConfirm.emailConfirmCode,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
            baseURL: config.url.API_URl,
        })
            .then(function (response) {
                if (response.data.status === 1) {
                    setMessageConfirm(response.data.message)
                    setErrorConfirm(true)
                } else {
                    localStorage.setItem("sumSubToken", response.data.sumSubToken)
                    localStorage.setItem("userID", response.data.userId)
                    document.location.href = response.data.redirectTo;
                }
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
            });
    }
    const resendConfirm = () => {
        axios.post('registration/resendConfirmCodes', {session: session}, {
            headers: {
                'Content-Type': 'application/json',
            },
            baseURL: config.url.API_URl,
        })
            .then(function (response) {
                setSeconds(120)
                setDisabledResendButton(true)
                setTrySubmit(trySubmit - 1)
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
            });
    }
    return (
        <Container
            component="main"
            maxWidth="xs"
            className={classes.main}
        >
            <Typography
                component="h1"
                variant="h5"
            >
                Contact information
            </Typography>
            <form
                className={classes.form}
                noValidate
            >
                <TextField
                    error={(dirty.email && !validate("email", params.email)) || unique.email}
                    onBlur={blurHandler}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    helperText={unique.email}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleChange}
                />
                <TextField
                    error={(dirty.phone && !validate('phone', params.phone)) || (unique.phone)}
                    onBlur={blurHandler}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                +
                            </InputAdornment>
                        ),
                    }}
                    helperText={unique.phone}
                    name="phone"
                    label="Phone"
                    type="phone"
                    id="phone"
                    onChange={handleChange}
                />
                <TextField
                    onBlur={blurHandler}
                    error={dirty.firstName && !validate("firstName", params.firstName)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="firstName"
                    label="First name"
                    type="text"
                    id="firstName"
                    onChange={handleChange}
                />
                <TextField
                    onBlur={blurHandler}
                    error={dirty.lastName && !validate("lastName", params.lastName)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    style={{marginBottom: "10px"}}
                    name="lastName"
                    label="Last name"
                    type="text"
                    id="lastName"
                    onChange={handleChange}
                />
                <FormControlLabel
                    style={{marginBottom: "10px"}}

                    control={
                        <Switch color="primary" checked={euCountry} onChange={handleChecked} name="euPerson"/>
                    }
                    label="Are you EU resident?"
                />
                <Button
                    fullWidth
                    disabled={!paramsValid(params) || trySubmit <= 0}
                    variant="contained"
                    color="primary"
                    onClick={submit}
                >
                    Next step
                </Button>
            </form>
            {trySubmit <= 0 ? <Typography
                color="error"
                variant="h7"
            >Sorry, but you have exceeded the allowed number of email and phone confirmation
                code requests. Please try to register later.</Typography> : null}
            <ConfirmModal
                hideBackdrop={true}
                trySubmit={trySubmit}
                disabledResendButton={disabledResendButton}
                setDisabledResendButton={setDisabledResendButton}
                seconds={seconds}
                setSeconds={setSeconds}
                emailConfirmCode={paramsConfirm.emailConfirmCode}
                phoneConfirmCode={paramsConfirm.phoneConfirmCode}
                message={messageConfirm}
                error={errorConfirm}
                open={openModal}
                setOpen={setOpenModal}
                params={paramsConfirm}
                setParams={setParamsConfirm}
                handleChange={handleChangeConfirm}
                resendConfirm={resendConfirm}
                confirm={confirm}
                handleClose={handleClose}
            />
        </Container>
    );
}
;
