import React from 'react'
import {
  PrimaryParagraph,
} from '../../UI/Evercash/Typography/Paragraph/PimaryParagraph'
import { PrimaryLink } from '../../UI/Evercash/Links'
import doc from '../../../assets/evercash/docs/Questionnaire for new client (legal entities).docx';
function EvercashFinalStep () {
  return (
    <React.Fragment>
      <PrimaryParagraph>
        Thank you for registration! In&nbsp;order to&nbsp;verify your account,
        please follow the link below to&nbsp;<PrimaryLink
        href={doc}
        target="_blank">download</PrimaryLink> questionnaire, send the
        required documents (the list of&nbsp;documents is&nbsp;at&nbsp;the
        bottom of&nbsp;the questionnaire). Please fill in&nbsp;detail all fields
        of&nbsp;the questionnaire. Send all documents from the list and
        completed application form to&nbsp;e-mail: <PrimaryLink
        href="mailto: support@evercash.eu">support@evercash.eu</PrimaryLink>
      </PrimaryParagraph>
    </React.Fragment>
  )
}

export default EvercashFinalStep