import React from 'react';
import styles from './ButtonPrimary.module.css';

export function ButtonPrimary({title="button", ...props}) {

    return (
        <button
            className={styles.css}
            {...props}
        >
            {title}
        </button>
    );
}
