/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import snsWebSdk from '@sumsub/websdk';
import {useHistory} from "react-router-dom";
import axios from "axios";
import {config} from "../../util/constans";
import {Box, Link, Typography} from "@material-ui/core";

export default function VerificationSumSub({session, sumSubToken}) {
    const history = useHistory();
    const [applicantToken, setApplicantToken] = useState(sumSubToken)

    useEffect(() => {
        sumSub();
    }, [applicantToken])

    function sumSub() {
        let snsWebSdkInstance = snsWebSdk.init(
            applicantToken,
            () => getNewToken()
        )
            .withBaseUrl(config.url.SUMSUB_API_URl)
            .withConf({
                lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
            })
            .on('onError', (error) => {
                console.log('onError', error)
            })
            .onMessage((type, payload) => {
                console.log('onMessage', type, payload)
            })
            .build();

        function getNewToken() {
            axios.get('registration/continueRegistration', {
                headers: {
                    'Content-Type': 'application/json',
                },
                baseURL: config.url.API_URl,
                params: {
                    session: session
                }
            })
                .then(function (response) {
                    setApplicantToken(response.data.sumSubToken)
                    localStorage.setItem("sumSubToken", response.data.sumSubToken)
                    console.log(response)
                    if (response.data.status === 404) {
                        // history.push("/")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/')
                })
                .then(function () {
                    // always executed
                });
        }

        return snsWebSdkInstance.launch('#sumsub-websdk-container')
    }


    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <div id="sumsub-websdk-container"/>
            <Typography
                style={{
                    marginTop: "10px",
                    minWidth: "200px",
                    maxWidth: "500px"
                }}
            >Please note that you can continue with the verification process by&nbsp;following the link received
                in&nbsp;your email. Please follow the instructions of&nbsp;the accepted documents for verification. Feel
                free to&nbsp;contact&nbsp;us by&nbsp;email: <Link
                    style={{cursor: "pointer"}}
                    // className={classes.linkEmail}
                    // to='#'
                    onClick={(e) => {
                        window.location = 'mailto:support@frtn.trade';
                        e.preventDefault();
                    }}
                >support@frtn.trade
                </Link> for any questions you may have.

            </Typography>
        </Box>
    );
}

