import React, { useState } from 'react'

import axios from 'axios'
import { config } from '../../../util/constans'
import { Input } from '../../UI/Evercash/Input'
import { FormEvercash } from '../../UI/Evercash/Form'
import { Field } from '../../UI/Evercash/Form/Field/Field'
import { ButtonPrimary } from '../../UI/Evercash/Buttons/ButtonPrimary'
import ConfirmModalEvercash from '../../Modal/Evercash/ConfirmModalEvercash'
import { useHistory } from 'react-router-dom'
import { H3 } from '../../UI/Evercash/Typography/H3'

export default function EvercashContactForm ({
  id,
}) {
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)
  const [messageConfirm, setMessageConfirm] = useState('')
  const [errorConfirm, setErrorConfirm] = useState(false)
  const [disabledResendButton, setDisabledResendButton] = useState(true)
  const [seconds, setSeconds] = useState(120)
  const [trySubmit, setTrySubmit] = useState(4)

  const [dirty, setDirty] = useState({
    email: false,
    phone: false,
    firstName: false,
    lastName: false,
    companyName: false,
  })
  const [unique, setUnique] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    companyName: '',
  })

  const [params, setParams] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    companyName: '',
  })

  const [paramsConfirm, setParamsConfirm] = useState({
    emailConfirmCode: '',
    phoneConfirmCode: '',
  })

  const handleChangeConfirm = (event) => {
    setParamsConfirm({
      ...paramsConfirm,
      [event.target.name]: event.target.value.toUpperCase(),
    })
  }

  const handleChange = (event) => {
    setParams({ ...params, [event.target.name]: event.target.value })
  }

  const handleClose = () => {
    setOpenModal(false)
    setDisabledResendButton(true)
    setSeconds(120)
  }

  const blurHandler = (e) => {
    if (e.target.name === 'email') {
      setDirty({ ...dirty, email: true })
    } else if (e.target.name === 'phone') {
      setDirty({ ...dirty, phone: true })
    } else if (e.target.name === 'companyName') {
      setDirty({ ...dirty, companyName: true })
    } else if (e.target.name === 'firstName') {
      setDirty({ ...dirty, firstName: true })
    } else if (e.target.name === 'lastName') {
      setDirty({ ...dirty, lastName: true })
    }
  }
  const paramsValid = (data) => {
    let flag = false
    for (let key in data) {
      if (validate(key, data[key])) {
        flag = true
      } else {
        flag = false
        break
      }
    }
    return flag
  }

  const validate = (name, value) => {
    if (name === 'email') {
      const emailRe = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      return emailRe.test(String(value).toLowerCase())
    } else if (name === 'phone') {
      const phoneRe = /[0-9]/
      return value.length > 7 && phoneRe.test(String(value).toLowerCase())
    } else if (name === 'companyName') {
      return value.length > 1
    } else if (name === 'firstName') {
      return value.length > 1
    } else if (name === 'lastName') {
      return value.length > 1
    }
  }

  const sendCreateRegistration = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setTrySubmit((trySubmit) => trySubmit - 1)
    setUnique({ ...unique, email: '', phone: '' })
    const data = {
      ...params,
      partner: localStorage.getItem('partner'),
      invoice: id,
      euPerson: false,
      isCompany: true,
    }
    // const data = { ...params, partner: 3, invoice: id }
    axios.post('registration/createRegistration', data, {
      headers: {
        'Content-Type': 'application/json',
        'partnerId': 2,
      },
      baseURL: config.url.API_URl,
    }).then(function (response) {
      if (response.data.status) {
        setUnique({
          ...unique,
          email: response.data.error.email,
          phone: response.data.error.phone,
        })
      } else {
        localStorage.setItem('session', response.data.session)
        setTrySubmit(trySubmit - 1)
        setOpenModal(true)
      }
    }).catch(function (error) {
      console.log(error)
    }).then(function () {
      // always executed
    })
  }

  const confirm = (event) => {
    event.preventDefault()
    event.stopPropagation()
    axios.post('registration/confirmRegistration', {
      phoneConfirmCode: paramsConfirm.phoneConfirmCode,
      emailConfirmCode: paramsConfirm.emailConfirmCode,
      session: localStorage.getItem('session'),
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: config.url.API_URl,
    }).then(function (response) {
      if (response.data.status === 1) {
        setMessageConfirm(response.data.message)
        setErrorConfirm(true)
      } else {
        // localStorage.setItem('userID', response.data.userId)
        localStorage.removeItem('partner')
        localStorage.removeItem('session')
        history.push('/final-step')
      }
    }).catch(function (error) {
      console.log(error)
    }).then(function () {
    })
  }
  return (
    <React.Fragment>
      <H3
        style={{ marginBottom: '10px', fontSize: '20px' }}
      >Contact information</H3>
      <FormEvercash
        onSubmit={sendCreateRegistration}
      >
        <Field
          id={'email'}
          title={'Email address *'}
          error={(dirty.email && !validate('email', params.email)) ||
          (unique.email) ? unique.email ? unique.email : 'email not valid' : ''}
        >
          <Input
            id="email"
            type="email"
            name="email"
            placeholder="Enter email address"
            value={params.email}
            onChange={handleChange}
            onBlur={blurHandler}
            error={(dirty.email && !validate('email', params.email)) ||
              (unique.email)}
          />
        </Field>

        <Field
          id={'phone'}
          title={'Phone *'}
          error={(dirty.phone && !validate('phone', params.phone)) ||
          (unique.phone) ? unique.phone
            ? unique.phone
            : 'phone number not valid' : ''}
        >
          <Input
            id="phone"
            type="tel"
            name="phone"
            placeholder="Enter phone number"
            value={params.phone}
            adornmentLeft={'+'}
            onChange={handleChange}
            onBlur={blurHandler}
            error={(dirty.phone && !validate('phone', params.phone)) ||
              (unique.phone)}
          />
        </Field>

        <Field
          id={'firstName'}
          title={'First name *'}
          error={(dirty.firstName &&
            !validate('firstName', params.firstName)) ||
          (unique.firstName) ? 'please enter first name' : ''}
        >
          <Input
            id="firstName"
            type="text"
            name="firstName"
            placeholder="Enter first name"
            value={params.firstName}
            onChange={handleChange}
            onBlur={blurHandler}
            error={(dirty.firstName &&
                !validate('firstName', params.firstName)) ||
              (unique.firstName)}
          />
        </Field>

        <Field
          id={'lastName'}
          title={'Last name *'}
          error={(dirty.lastName && !validate('lastName', params.lastName)) ||
          (unique.lastName) ? 'please enter last name' : ''}
        >
          <Input
            id="lastName"
            type="text"
            name="lastName"
            placeholder="Enter last name"
            value={params.lastName}
            onChange={handleChange}
            onBlur={blurHandler}
            error={(dirty.lastName && !validate('lastName', params.lastName)) ||
              (unique.lastName)}
          />
        </Field>

        <Field
          id={'companyName'}
          title={'Company name *'}
          error={(dirty.companyName &&
            !validate('companyName', params.companyName)) ||
          (unique.companyName) ? 'enter company name' : ''}
        >
          <Input
            id="companyName"
            type="text"
            name="companyName"
            placeholder="Enter company name"
            value={params.companyName}
            onChange={handleChange}
            onBlur={blurHandler}
            error={(dirty.companyName &&
                !validate('companyName', params.companyName)) ||
              (unique.companyName)}
          />
        </Field>
        <ButtonPrimary
          disabled={!paramsValid(params)}
          title="Next step"
          type="submit"
        />
      </FormEvercash>

      <ConfirmModalEvercash
        hideBackdrop={true}
        trySubmit={trySubmit}
        disabledResendButton={disabledResendButton}
        setDisabledResendButton={setDisabledResendButton}
        seconds={seconds}
        setSeconds={setSeconds}
        emailConfirmCode={paramsConfirm.emailConfirmCode}
        phoneConfirmCode={paramsConfirm.phoneConfirmCode}
        message={messageConfirm}
        error={errorConfirm}
        open={openModal}
        setOpen={setOpenModal}
        params={paramsConfirm}
        setParams={setParamsConfirm}
        handleChange={handleChangeConfirm}
        resendConfirm={sendCreateRegistration}
        confirm={confirm}
        handleClose={handleClose}
      />
    </React.Fragment>
  )
}
;
