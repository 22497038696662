import React from 'react';
import styles from './H3.module.css';

export function H3({children, ...props}) {
    return (
        <h2
            className={styles.css}
            {...props}
        >
            {children}
        </h2>
    );
}
