import React from 'react';
import styles from './input.module.css';

export function Input({title, id, error, adornmentLeft, adornmentRight, onClick, ...props}) {
    return (
            <div className={styles.inputBox}>
                <input
                    className={ adornmentLeft ? styles.inputPaddingAdornmentLeft : adornmentRight ? styles.inputPaddingAdornmentRight : styles.input}
                    style={error ? {borderColor: "#DF2929"} : {}}
                    {...props}
                />

                {
                  adornmentRight &&
                    <div
                        className={styles.adornmentRight}
                        onClick={onClick}
                    >{adornmentRight}</div>
                }
                {
                  adornmentLeft &&
                    <div
                        className={styles.adornmentLeft}
                        onClick={onClick}
                    >{adornmentLeft}</div>
                }
            </div>
    );
}