/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import ContactForm from './components/Registration/ContactForm'
import VerificationSumSub from './components/SumSub/VerificationSumSub'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import SubmissionAccepted from './page/SubmissionAccepted'
import { config } from './util/constans'
import { evercashRoutes } from './routes'
import { EvercashLayout } from './components/UI/Evercash/Layouts'

export default function App () {
  const sumSubToken = 'a'
  const search = window.location.search.slice(1)
  const [loading, setLoading] = useState(true)
  const [params, setParams] = useState({
    partner: '',
    id: '',
  })

  const [session, setSession] = useState(window.location.pathname.slice(3))
  const [statusSession, setStatusSession] = useState(1)

  useEffect(() => {
    if (search.includes('partner')) {
      const searchPartner = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === '' ? value : decodeURIComponent(value)
        })
      setParams({ ...params, partner: searchPartner.partner })
    }
    if (search.includes('id')) {
      const searchID = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === '' ? value : decodeURIComponent(value)
        })
      setParams({ ...params, id: searchID.id })
    }

    if (window.location.hostname.includes(config.partner.evercahs.host)) {
      localStorage.setItem('partner', config.partner.evercahs.name);
      if (search.includes('id')) {
        const searchID = JSON.parse(
          '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === '' ? value : decodeURIComponent(value)
          })
        setParams({ ...params, id: searchID.id })
      }
    }
    setLoading(false)
  }, [])

  if (loading) {
    return <h1>...Loading</h1>
  }

  return (
    <Router>
      <Switch>
        {
          window.location.hostname.includes(config.partner.evercahs.host)
            ?
            <EvercashLayout>
              {evercashRoutes.map(route =>
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />,
              )}
            </EvercashLayout>
            :
            <Route
              exact
              path={'/'}
            >
              <ContactForm
                id={params.id}
                partner={params.partner}
                setSession={setSession}
                session={session}
                statusSession={statusSession}
                setStatusSession={setStatusSession}
              />
            </Route>
        }
        <Route
          path={`/s/${session}`}
        >
          <VerificationSumSub
            session={session}
            sumSubToken={sumSubToken}
          />
        </Route>
        <Route
          path={`/submission-accepted`}
        >
          <SubmissionAccepted/>
        </Route>
        <Redirect to={'/'}/>
      </Switch>
    </Router>
  )
};
