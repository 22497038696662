import React from 'react';
import styles from './field.module.css';

export function Field({children, id, error, title}) {
    return (
        <div
            className={styles.wrapper}
        >
            <div
                className={styles.labelBox}
            >
                <label
                    className={styles.label}
                    htmlFor={id}
                >
                    {title}
                </label>
                {
                    error && <span className={styles.error}>{error}</span>
                }
            </div>
            {children}
        </div>
    );
}
