import React, { useEffect, useState } from 'react'

import { makeStyles, Modal } from '@material-ui/core'
import { H2 } from '../../UI/Evercash/Typography/H2'
import { FormEvercash } from '../../UI/Evercash/Form'
import { Input } from '../../UI/Evercash/Input'
import { Field } from '../../UI/Evercash/Form/Field/Field'
import { ButtonPrimary } from '../../UI/Evercash/Buttons/ButtonPrimary'
import styles from './modalEvercash.module.css';
import { Eye, EyeSlash } from '../../../assets/evercash/Icons'

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: 440,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function ConfirmModalEvercash ({
  open,
  setOpen,
  handleChange,
  confirm,
  message,
  error,
  phoneConfirmCode,
  emailConfirmCode,
  resendConfirm,
  seconds,
  setSeconds,
  disabledResendButton,
  setDisabledResendButton,
  trySubmit,
}) {
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
    setDisabledResendButton(true)
    setSeconds(120)
  }
  const [showCode, setShowCode] = useState({showPhoneCode: false, showEmailCode: false});

  useEffect(() => {
    if (open && seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    }
    if (open && seconds <= 0 && trySubmit > 0) {
      setDisabledResendButton(false)
    }

  }, [
    seconds,
    open,
    setSeconds,
    disabledResendButton,
    setDisabledResendButton,
    trySubmit,
    setOpen])
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.paper}>
        <H2
          style={{ marginBottom: 10 }}
        >
          Please enter confirmation codes
        </H2>

        <FormEvercash
          onSubmit={confirm}
        >

          <Field
            id={'emailConfirmCode'}
            title={'Email confirm'}
            error={error
              ? message
              : ''}
          >
            <Input
              value={emailConfirmCode}
              error={error}
              id="emailConfirmCode"
              name="emailConfirmCode"
              type={showCode.showEmailCode ? "text" : "password"}
              placeholder={"Enter email code"}
              adornmentRight={showCode.showEmailCode ? <Eye/> : <EyeSlash/>}
              onClick={()=> setShowCode({...showCode, showEmailCode: !showCode.showEmailCode})}
              onChange={handleChange}
            />
          </Field>
          <Field
            id={'phoneConfirmCode'}
            title={'Phone confirm'}
            error={error
              ?  message
              : ''}
          >
            <Input
              value={phoneConfirmCode}
              error={error}
              id="phoneConfirmCode"
              name="phoneConfirmCode"
              type={showCode.showPhoneCode? "text" : "password"}
              placeholder={"Enter phone code"}
              adornmentRight={showCode.showPhoneCode ? <Eye/> : <EyeSlash/>}
              onClick={()=> setShowCode({...showCode, showPhoneCode: !showCode.showPhoneCode})}
              onChange={handleChange}
            />
          </Field>
          <div
            className={styles.buttonGroup}
          >
            <ButtonPrimary
              style={{ marginRight: 10 }}
              disabled={disabledResendButton || trySubmit <= 0}
              title={`Resend code ${disabledResendButton && trySubmit > 0
                ? seconds
                : ''}`}
              onClick={resendConfirm}
            />
            <ButtonPrimary
              type="submit"
              title="Confirm"
            />
          </div>
        </FormEvercash>
      </div>
    </Modal>
  )
};
