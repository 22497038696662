import React from 'react';

import logo from "../../../../assets/evercash/img/evercash_logo.svg";
import styles from "./authLayout.module.css";
import { H2 } from '../Typography/H2'
export function EvercashLayout({children}) {

    return (
        <main
            className={styles.main}
        >
            <div
                className={styles.wrapper}
            >
                <div
                    className={styles.formBox}
                >
                    <img
                        className={styles.logo}
                        src={logo}
                        alt="Logo"
                    />
                    <H2
                      style={{ marginBottom: '20px', fontSize: '30px' }}
                    >Legal entity registration
                    </H2>
                    {children}
                </div>

            </div>
        </main>
    );
}