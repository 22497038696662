import React from 'react';
import {Container, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

}));

const SubmissionAccepted = () => {
    const classes = useStyles();

    return (
        <Container
            component="main"
            maxWidth="xs"
            className={classes.main}
        >

            <Typography
                variant={"h5"}
                style={{
                    marginTop: "10px",
                    minWidth: "200px",
                    maxWidth: "500px"
                }}
            >
                The application has been accepted, we&nbsp;will contact you to&nbsp;continue registration as&nbsp;a&nbsp;legal entity.
            </Typography>

        </Container>
    );
};

export default SubmissionAccepted;