let PROD = {
    partner: {
        evercahs:{
            id: 3,
            name: "evercash-parcent",
            host: "parcent"
        }
    },
    url: {
        SUMSUB_API_URl: 'https://api.sumsub.com',
        API_URl: 'https://reg.bertes.eu/api/'
    }
};

let STG = {
    partner: {
        evercahs:{
            id: 3,
            name: "evercash-parcent",
            host: "parcent"
        }
    },
    url: {
        SUMSUB_API_URl: 'https://api.sumsub.com',
        API_URl: 'https://reg-stg.bertes.eu/api/'
    }
};

let DEV = {
    partner: {
        evercahs:{
            id: 3,
            name: "evercash-parcent",
            host: "parcent"
        }
    },
    url: {
        SUMSUB_API_URl: 'https://api.sumsub.com',
        API_URl: 'https://reg-dev.bertes.eu/api/'
    }
};

export const config = process.env.REACT_APP_STAGE === 'production' ? PROD : process.env.REACT_APP_STAGE === 'stg' ? STG : DEV;

