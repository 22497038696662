import React from 'react';
import styles from './form.module.css';

export function FormEvercash({children, ...props}) {
    return (
        <form
            className={styles.form}
            {...props}
        >
            {children}
        </form>
    );
}